import React from "react"
import SEO from "../../components/seo"

import '../../scss/youmedico-global.scss';

import Image1 from "../../assets/img/youMedico/services/Img1.png"
import Image2 from "../../assets/img/youMedico/services/Img2.png"
import Image3 from "../../assets/img/youMedico/services/Img3.png"
import Image4 from "../../assets/img/youMedico/services/Img4.png"
import SingleForm from "../../components/singleForm";

const ServicesPage = () => {
  return (
    <div>
      <SEO title="Youmedico Services" keywords={[`youpal`, `services`, 'youMedico']} />
      <section className="container servicesMain">
        <h2>Youmedico’s Solutions</h2>
        <p>
        With state of the art solutions, Youmedico is a pioneer in the field of modern and futuristic medical care services
        </p>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col">
            <img className="sideImg mainImage" src={Image1} />
          </div>
        </div>
        </section>

      <section className="container aboutUsPageSection productsSection" style={{ marginTop: '300px' }}>
        <div className="row">
          <div className="col">
            <img className="sideImgAdv" src={Image2} />
          </div>
          <div className="col sideContent text-left">
            <h2>Telemedicine</h2>
            <p className="firstLine">
            Our telemedicine solution will let you improve Patient Outcomes and increase the overall revenue of your clinic. It has numerous benefits and use-cases, which includes but not limited to:

</p><p>•	Managing schedule - Through our Web apps, users can have full control over their schedules. It increases their time management and the quality of daily life.

</p><p>•	Defining session length – Users may want to set some limitations to their sessions, such as the length. They can easily input their preferred session duration and make sure no one will exceed that time limit.

</p><p>•	Decreasing no-shows – One of the challenges in telemedicine is having too many sessions where the patient is absent. Thanks to the appointment confirmations and reminders indicating upcoming sessions, we minimize the possibility of such undesirable cases.

</p><p>•	Secure payments - Besides functional features, we also make sure all your payments are handled most securely. Our software uses highly reliable and trusted payment solutions to proceed with online transactions.

</p><p>•	Setting pricing – in Youmedico, users are able to manage their pricing for telemedicine sessions. They can offer different pricing options or even set it as free to encourage patients to contact.

</p><p>•	Transaction history – Transparency is one of Youmedico's core principles. We offer various online payment options and let users check their billing history whenever they want. 

</p><p>•	Timely follow-ups – Using the power of automation in telemedicine improves users' efficiency. Users can set automatic rules to follow-up with their patients based on the conditions and personal health status.

</p><p>•	Consultation through messaging – Calls or video conferences are not the only way of communication between patients and healthcare providers. We also have a message consultation feature for those who prefer writing form over visual communication.

</p><p>•	Consultation notes – Users of our telemedicine solutions can also take a note during, before, or after the consultation. That helps to keep all the details organised and accessible.

</p><p>•	Database – Our software keeps the medical history of all patients in one place. Thanks to this feature, doctors can build effective collaboration with their patients effortlessly.

</p>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection">
        <div className="row">
          <div className="col sideContent text-left">
            <h2>Remote Patient/Health
Monitoring</h2>
            <p>
            RPM is what makes us pioneers. Technology is changing the world faster than expected. Devices, tools, and software solutions are adapting to these fast-changing trends to become the new norm. 
            </p><p>Using the newest technological resources, we pay immense attention to designing the fundamentals of remote patient care. With our exstensive understanding and experince of IoT and with access to our own cloud infrastructure, the only limit is our imagination. Our aim is to create RPM solutions that are able to track and monitor chronic diseases and regular health check-ups but also complement alongside our telemedicine solutions available. This will have a great impact on today’s society by making health more accessible, and therefore, lower the cost per treated patient when examination gets more accessible.

            </p><p>Remote patient monitoring (RPM) utilizes digital setup of sensors, IoT-platform and devices to gather medical and various kinds of health data from patients and electronically transfer that data in a secure way to caretakers (doctors & nurses etc.) in another location for assessment. This kind of service provides a healthcare practitioner the ability to track patients' health information from anywhere. 

            </p><p>Our monitoring solutions can gather a broad range of health information from the point of care, such as necessary signs, weight, blood sugar and pressure, oxygen levels in the blood, heart rate, and electrocardiograms.
</p>
          </div>
          <div className="col">
            <img className="sideImg imgProducts" src={Image3} />
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection lastSection">
        <div className="row">
          <div className="col">
            <img className="sideImg imgProducts" src={Image4} />
          </div>
          <div className="col sideContent text-left">
            <h2>Global Medical Staff Database</h2>
            <p>
            The days of researching for healthcare providers are long gone. Our Global Medical Staff Database collects and gathers hundreds of doctors specializing in various medical branches. The best part of it is that it's fully available to both healthcare providers and patients.

            </p><p>If you're looking for a professional doctor who can remotely take care of you, our digital healthcare sourcing platform is what you need. Our list of professionals is updated frequently with new information. We manually inspect and verify every healthcare provider in our database.

            </p><p>The clinics can also use our Global Medical Staff Database to find and recruit the best healthcare specialist to fill vacant positions. By defining our database as a single source of truth for your clinic, you can make sure that you never get challenged by the lack of required staff members.

            </p>
          </div>
        </div>
      </section>

      <section className="container aboutUsPageSection conctactSection">
        <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
        </div>
      </section>
    </div>
  )
}

export default ServicesPage
